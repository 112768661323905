import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Metadata`}</h1>
    <ul>
      <li parentName="ul">{`Metadata is information about the people participating in the polls which is not directly related to the `}<a parentName="li" {...{
          "href": "/topic",
          "title": "topic"
        }}>{`topic`}</a>{``}</li>
      <li parentName="ul">{`This could be a `}<a parentName="li" {...{
          "href": "/comment",
          "title": "comment"
        }}>{`comment`}</a>{` such as "I live in a city" or "I am retired"`}</li>
      <li parentName="ul">{`When moderating comments, you will have the opportunity to denote comments as 'metadata' as a convenience, though it is not necessary or required`}</li>
      <li parentName="ul">{`If a comment is denoted as metadata, it is excluded from clustering and computation, to avoid clustering solely on demographic information, which is a powerful divisive signal for the algorithms used, but is, for the purposes of analyzing opinions, noise`}</li>
      <li parentName="ul">{`If a comment is denoted as metadata, it appears in its own section of the automated `}<a parentName="li" {...{
          "href": "/report",
          "title": "report"
        }}>{`report`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      